import axios from 'axios'
import Vue from 'vue'

//
// Gets the token from the HTMl meta tag
//
function get_token_from_html() {
  return document.head.querySelector('meta[name="user-token"]')?.content
}

// IMPORTANT
// We need to set the jwt token defined on the HTML code onto the variable as soon as
// possible to avoid breaking the first data loading requests
window.the_vl_jwt = get_token_from_html()

// Config parameters
const REFRESH_IF_EXPIRING_IN_S = 15
const REFRESH_VARIATION_S = 3 * 60
const DAEMON_POLLING_INTERVAL_S = 10 // Must be smaller than REFRESH_IF_EXPIRING_IN_S
const THROTTLE_TOKEN_REFRESH_S = 15

// Singleton mutex
let allow_refresh = true

//
// Parses a JWT serialized token into a JS object
//
function parse_jwt(token) {
  var base64_url = token.split('.')[1]
  var base64 = base64_url.replace(/-/g, '+').replace(/_/g, '/')
  var payload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(payload)
}

//
// Returns true if the token is expiring in less than X seconds
//
function is_token_expiring_in(seconds) {
  if (!window.the_vl_jwt) {
    window.the_vl_jwt = get_token_from_html()
  }
  if (window.the_vl_jwt) {
    const exp = parse_jwt(window.the_vl_jwt).exp * 1000
    const now = new Date().getTime()
    const zero = now + seconds * 1000
    if (exp <= zero) {
      return true
    }
  }
  return false
}

//
// Make sure we don't have a loop of reauth requests
//
function throttle_is_blocked() {
  const now = new Date().getTime()
  const long_ago = now - THROTTLE_TOKEN_REFRESH_S * 1000
  const throttle = window.the_vl_jwt_throttle
  if (throttle && throttle > long_ago) {
    if (!window.the_vl_jwt_throttle_notified) {
      window.the_vl_jwt_throttle_notified = true
      console.error('AUTH THROTTLE!')
      // alert('AUTH ERROR!')
    }
    return true
  }
  window.the_vl_jwt_throttle = now
  return false
}

//
// Refresh the token by contacting the appropriate route and injecting the new token globally
//
function refresh_the_token(original_error) {
  if (throttle_is_blocked()) return

  allow_refresh = false
  return Vue.smcb_axios.get(`/me`).then(
    result => {
      window.the_vl_jwt = result.data.token
      allow_refresh = true

      if (original_error) {
        original_error.config.headers.set('Authorization', `Bearer ${result.data.token}`)
        return axios.request(original_error.config)
      }
    },
    error => {
      if (error.response.data.action === 'logout') {
        // Avoid including ?session_state= in the redirect url
        const redirect_url = encodeURIComponent(window.location.origin + window.location.pathname)
        window.location.replace(`/logout?redirect_url=${redirect_url}`)
      } else {
        window.location.reload(true)
      }
    }
  )
}

//
// Interval method that makes sure that the token never actually expires on a request
//
function start_auth_daemon() {
  //
  // NQ-414 We introduce a random offset to the refresh timeout as an attempt to avoid the emergence
  // of binary patterns in the request count to the server due to most of our users starting to work
  // punctually at the same hour of day.
  //
  // It starts with a large value to have immediate divergence and then gradually shrinks to zero in
  // order to decrease the number of total requests
  //
  let random_salt = Math.floor(REFRESH_VARIATION_S * Math.random())
  function check() {
    const max_seconds = REFRESH_IF_EXPIRING_IN_S + random_salt
    if (is_token_expiring_in(max_seconds)) {
      random_salt = Math.floor(random_salt * 0.9)
      refresh_the_token()
    }
  }
  setInterval(check, DAEMON_POLLING_INTERVAL_S * 1000)
}

//
// Adds an interceptor to the axios instance, that gets the refresh token from '/me', which
// returns the refreshed token as a string in 'result.body.token'. This is just a precaution and
// should not in theory be necessary, as this function also lauches a 'daemon' function that makes
// sure the token is refreshed before ever expiring.
//
function create_auth_refresh_interceptor() {
  if (!window.the_vl_jwt) return

  // Start our auto-refresh 'daemon'
  start_auth_daemon()

  // Hook our interceptor middleware into axios
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (allow_refresh && error.response.status === 401) {
        //
        // If for some reason the token did not get refreshed by the 'daemon',
        // try to refresh it on the fly:
        //
        return refresh_the_token(error.config)
      }
      return Promise.reject(error)
    }
  )
}

export default create_auth_refresh_interceptor
